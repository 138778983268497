import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SurveyType } from '../../enums';
import { SurveyApiService } from '../survey-api-service';

@Component({
  selector: 'app-survey-success-page',
  templateUrl: './survey-success-page.component.html',
  styleUrls: ['./survey-success-page.component.scss']
})
export class SurveySuccessPageComponent implements OnInit {
  createDate = '';
  engagementName = '';
  isStudiosSurvey = false;
  uuid = '';

  constructor(
    private router: Router,
    public datepipe: DatePipe,
    private surveyApi: SurveyApiService,
    private route: ActivatedRoute
  ) {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
  }

  ngOnInit() {
    this.surveyApi.getSurveyDetails(this.uuid).subscribe(
      details => {
        this.isStudiosSurvey = details.surveyId == SurveyType.STUDIOS;
        let date = new Date(details.createDate);
        date.setMonth(date.getMonth() - 1);
        this.createDate = this.datepipe.transform(date, 'MMMM, YYYY');
        if (this.isStudiosSurvey) {
          this.surveyApi.getStudiosDetailsForSurveyInviteId(details.surveyInviteId).subscribe(
            data => {
              this.engagementName = data.roboheadEngagementName;
            },
            error => {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              console.log(`Failed to read studios details for survey invite ${details.surveyInviteId}, Error: ${error}`);
            }
          );
        }
      },
      error => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        console.log(`Failed to read survey details for survey uuid ${this.uuid}, Error: ${error}`);
      }
    );
  }
}
