import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EnvironmentToken } from '../../util';
import { ApiService } from '../api.service';
import { AuthService, TokenResponse } from '../service/auth.service';
import { Environment } from '../../typings';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private authSub: Subscription;
  surveyFeature = true;
  returnUrl = '/hello';

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    @Inject(EnvironmentToken) private environment: Environment
  ) {
    this.route.queryParams.subscribe(params => {
      if (params.returnUrl) {
        this.returnUrl = params.returnUrl;
      }
    });
  }

  ngOnInit() {
    this.authSub = this.route.queryParamMap
      .pipe(
        switchMap(params => {
          if (this.surveyFeature) {
            if (this.auth.hasAuthentication()) {
              // If we are already authenticated then skip right into the application
              return of(null);
            } else if (params.get('authenticated')) {
              return this.api.requestAuthToken(this.environment);
            } else {
              location.replace(`${this.environment.cloudwallHost}/webwall/login?authSource=${encodeURIComponent(location.href)}`);
              return new Subject(); // Return an empty Subject to stall forever since page is unloading
            }
          } else {
            this.auth.removeAuthentication();
            return of(null);
          }
        })
      )
      .subscribe(
        (response: TokenResponse) => {
          if (response) this.auth.storeTokenAuthentication(response);
          void this.router.navigateByUrl(decodeURIComponent(this.returnUrl));
        },
        err => {
          console.error('Authentication failure!', err);
          throw err;
        }
      );
  }

  ngOnDestroy() {
    // Release subscription, if any
    if (this.authSub) this.authSub.unsubscribe();
  }
}
