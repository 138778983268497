import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import {Answer, StudiosSurveyDetails, SurveyDetails} from '../../typings';

@Injectable({ providedIn: 'root' })
export class SurveyApiService {
  constructor(private apiService: ApiService) {}

  getSurveyDetails(uuid: string): Observable<SurveyDetails> {
    const params = new HttpParams();
    return this.apiService.httpWrapper<SurveyDetails>({
      method: 'GET',
      url: `/api/survey/survey-details/${uuid}`,
      authenticated: true,
      params
    });
  }

  saveResponse(surveyId: number, answers: Answer[], uuid: string): Observable<void> {
    return this.apiService.httpWrapper<void>({
      method: 'POST',
      url: `/api/survey/survey-details/submit`,
      body: {
        surveyId: surveyId,
        answerList: answers,
        surveyToken: uuid
      }
    });
  }

  getStudiosDetailsForSurveyInviteId(surveyInviteId: number): Observable<StudiosSurveyDetails> {
    const params = new HttpParams();
    return this.apiService.httpWrapper<StudiosSurveyDetails>({
      method: 'GET',
      url: `/api/survey/studios/${surveyInviteId}`,
      authenticated: true,
      params
    });
  }
}
