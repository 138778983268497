import { InjectionToken } from '@angular/core';
import { Environment } from './typings';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

export const cache = () => <T>(source: Observable<T>) => source.pipe(publishReplay(1), refCount());

export function blobToString(data: Blob): Promise<string> {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = () => res(reader.result as string);
    reader.onerror = err => rej(err.toString());
    reader.readAsText(data);
  });
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const EnvironmentToken = new InjectionToken<Environment>('environment');
