/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/ban-ts-comment, @typescript-eslint/no-var-requires*/

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'survey-app';

  constructor(private translate: TranslateService) {
    // @ts-ignore
    translate.setTranslation('en-US', require('../assets/i18n/en_us.json'));
    translate.setDefaultLang('en-US');
  }
}
