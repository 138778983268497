import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../web-components/abstract-value-accessor';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export const StarsRequired = (control: AbstractControl): ValidationErrors | null => {
  return control.value > 0 || control.value === 'NA' ? null : { required: true };
};

@Component({
  selector: 'app-five-star-rating-radio-buttons',
  templateUrl: './five-star-rating-radio-buttons.component.html',
  styleUrls: ['./five-star-rating-radio-buttons.component.scss'],
  providers: [MakeProvider(FiveStarRatingRadioButtonsComponent)]
})
export class FiveStarRatingRadioButtonsComponent extends AbstractValueAccessor {
  @Input() label = '';
  @Input() lowScoreLabel = '';
  @Input() highScoreLabel = '';
  @Input() errorMessage = '';
  @Input() invalid = false;
  @Input() formControlName: string;
  @Output() valueChanged = new EventEmitter();

  radioSelected($event: any) {
    this.onChange($event.value);
    this.valueChanged.emit($event.value);
  }
}
