export enum SurveyType {
  STUDIOS = 4
}

export enum QuestionType {
  SHORT_TEXT = 1,
  LONG_TEXT = 2,
  DROPDOWN = 3,
  MULTIPLE_CHOICE = 4,
  STAR_RATING = 5,
  YES_NO = 6,
  SENTIMENT = 7
}
