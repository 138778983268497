import { Attribute, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../abstract-value-accessor';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [MakeProvider(CheckboxComponent)]
})
export class CheckboxComponent extends AbstractValueAccessor {
  @Input() id: string;
  @Input() labelPosition: 'before' | 'after' = 'after';
  @Input() invalid = false;
  @Output() valueChanged = new EventEmitter();

  constructor(@Attribute('formControlName') public formControlName: string) {
    super();
  }

  checkBox($event: any) {
    this.onChange($event.checked);
    this.valueChanged.emit($event.checked);
  }
}
