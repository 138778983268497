import { EventEmitter, forwardRef, Output, Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive()
export abstract class AbstractValueAccessor implements ControlValueAccessor {
  @Output() focusEvent = new EventEmitter<FocusEvent>();
  @Output() blurEvent = new EventEmitter<FocusEvent>();

  locked = false;
  focused = false;
  dirty = false;
  disabled = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _value: string | number = null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = () => {};

  onBlur(event: FocusEvent) {
    this.focused = false;
    this.blurEvent.emit(event);
  }

  onFocus(event: FocusEvent) {
    this.focused = true;
    this.focusEvent.emit(event);
  }

  onInput() {
    this.dirty = true;
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
  }

  lock() {
    this.locked = true;
  }

  unlock() {
    this.locked = false;
  }

  change($event) {
    this.onChange($event.target.value);
  }

  writeValue(val: any): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

/**
 * @param type
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function MakeProvider(type: any) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type),
    multi: true
  };
}
