<div class="five-star-rating" [ngClass]="invalid && !_value ? 'error-color' : 'normal-color'">
  <div class="grid-x grid-padding-x">
    <div class="cell auto">
      <div class="cell small-12 medium-12 large-12 question-label">{{label}}</div>
      <div class="small-12 medium-12 large-12 question-label"
           [ngClass]="invalid && !_value ? 'show' : 'hide'">{{errorMessage}}</div>
      <div class="radio-buttons grid-x grid-padding-x">
        <div class="radio-side-label cell shrink low-score-label hide-for-small-only"><span>{{lowScoreLabel}}</span></div>
        <div class="score-div cell shrink">
          <mat-radio-group>
            <mat-radio-button id="{{formControlName}}-NA" (change)="radioSelected($event)" value="NA" class="last-radio">N/A </mat-radio-button>
            <mat-radio-button id="{{formControlName}}-1" (change)="radioSelected($event)" value="1">1</mat-radio-button>
            <mat-radio-button id="{{formControlName}}-2" (change)="radioSelected($event)" value="2">2</mat-radio-button>
            <mat-radio-button id="{{formControlName}}-3" (change)="radioSelected($event)" value="3">3</mat-radio-button>
            <mat-radio-button id="{{formControlName}}-4" (change)="radioSelected($event)" value="4">4</mat-radio-button>
            <mat-radio-button id="{{formControlName}}-5" (change)="radioSelected($event)" value="5">5</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="radio-side-label cell shrink high-score-label hide-for-small-only"><span>{{highScoreLabel}}</span></div>
      </div>
    </div>
  </div>
</div>
