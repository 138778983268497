import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyApiService } from './survey-api-service';
import { StarsRequired } from '../five-star-rating-radio-buttons/five-star-rating-radio-buttons.component';
import { QuestionType, SurveyType } from '../enums';
import { DatePipe } from '@angular/common';
import { Answer, StudiosSurveyDetails, SurveyDetails } from "../../typings";

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent {
  formGroup: FormGroup;
  uuid: string;
  surveyDetails: SurveyDetails;
  studiosSurveyDetails: StudiosSurveyDetails;
  dataLoaded: Promise<boolean>;
  submitted = false;
  isStudiosSurvey = false;
  surveyDate = '';
  currentThemeId = 0;

  constructor(
    private route: ActivatedRoute,
    private surveyApi: SurveyApiService,
    private formBuilder: FormBuilder,
    protected router: Router,
    public datePipe: DatePipe
  ) {
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.surveyApi.getSurveyDetails(this.uuid).subscribe(
      details => {
        details.questions = details.questions.sort(({ questionOrder: a }, { questionOrder: b }) => a - b);
        this.surveyDetails = details;

        if (details.surveyId == SurveyType.STUDIOS) {
          this.surveyApi.getStudiosDetailsForSurveyInviteId(this.surveyDetails.surveyInviteId).subscribe(
            data => {
              this.studiosSurveyDetails = data;
              this.isStudiosSurvey = true;
            },
            error =>
              console.log(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `Failed to read studios specific survey details for survey invite id: ${this.surveyDetails.surveyInviteId}. Error: ${error}`
              ),
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            () => (this.dataLoaded = Promise.resolve(true))
          );
        }
        if (this.surveyDetails.surveyCompleted) {
          this.router.navigate(['survey-success', this.uuid]);
        } else {
          this.buildFormGroup();
        }

        let date = new Date(this.surveyDetails.createDate);
        date.setMonth(date.getMonth() - 1);
        this.surveyDate = this.datePipe.transform(date, 'MMMM YYYY');
      },
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      error => console.log(`Failed to read survey details for uuid: ${this.uuid}. Error: ${error}`)
    );
  }

  buildFormGroup() {
    const group = {};
    this.surveyDetails.questions.forEach(question => {
      let formControl;
      if (question.required) {
        switch (question.questionTypeId) {
          case QuestionType.STAR_RATING:
            formControl = new FormControl(0, StarsRequired);
            break;
          case QuestionType.YES_NO:
            formControl = new FormControl('', Validators.required);
            break;
          case QuestionType.LONG_TEXT:
            formControl = new FormControl('', Validators.required);
            break;
        }
      } else {
        formControl = new FormControl('');
      }
      group[`question${question.id}`] = formControl;
    });
    this.formGroup = new FormGroup(group);
  }

  hasRequiredError(id) {
    const control: AbstractControl = this.formGroup.get(`question${id}`);
    if (control.errors && this.submitted) {
      if ((Object.keys(control.errors).indexOf('required') > -1)) {
        return true;
      }
    }
    return false;
  }

  submitSurvey() {
    this.submitted = true;
    if (this.formGroup.valid) {
      const surveyAnswers: Answer[] = [];
      this.surveyDetails.questions.forEach(question => {
        const name = `question${question.id}`;
        const answer = {} as Answer;
        answer.questionId = question.id;
        answer.answerValues = [this.formGroup.controls[name].value];
        surveyAnswers.push(answer);
      });
      this.surveyApi.saveResponse(this.surveyDetails.surveyId, surveyAnswers, this.uuid).subscribe(
        () => {
          this.router.navigate(['survey-success', this.uuid]);
        },
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        error => console.log(`Failed to save survey details for uuid: ${this.uuid}. Error: ${error}`)
      );
    } else {
      this.scrollToFirstInvalidControl();
    }
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = document.body.querySelector(
      "form .ng-invalid"
    );
    firstInvalidControl.setAttribute('tabindex', '0');
    firstInvalidControl.focus();
  }

  showTheme(themeId) {
    if (this.currentThemeId === themeId) {
      return false;
    }
    this.currentThemeId = themeId;
    return true;
  }
}
