import { Injectable } from '@angular/core';

/**
 * @param str
 */
export function tryParseJson(str: string): any {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

const APP_PREFIX = 'survey.';

@Injectable()
export class StorageService {
  private readonly storageSupported: boolean = false;

  constructor() {
    try {
      const key = `${APP_PREFIX}test`;
      localStorage.setItem(key, '1');
      localStorage.removeItem(key);
      this.storageSupported = true;
    } catch (e) {
      // Ignored.
    }
  }

  get<T>(key: string, defaultValue?: T): T {
    if (this.storageSupported) {
      const value = localStorage.getItem(APP_PREFIX + key);
      if (value !== null) {
        return tryParseJson(value);
      }
    }
    return defaultValue || null;
  }

  put(key: string, item: any): void {
    if (!this.storageSupported) {
      return;
    }
    if (typeof item !== 'string') {
      item = JSON.stringify(item);
    }
    localStorage.setItem(APP_PREFIX + key, item);
  }

  remove(key: string): void {
    if (this.storageSupported) {
      localStorage.removeItem(key);
    }
  }
}
