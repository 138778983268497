<div class="yes-no" [ngClass]="invalid && !_value ? 'error-color' : 'normal-color'">
  <div class="grid-x grid-padding-x">
    <div class="cell auto">
      <div class="cell small-12 medium-12 large-12 question-label">{{label}}</div>
      <div class="small-12 medium-12 large-12 question-label"
           [ngClass]="invalid && !_value ? 'show' : 'hide'">{{errorMessage}}</div>
      <div class="radio-buttons">
        <div class="score-div cell shrink">
          <mat-radio-group>
            <mat-radio-button (change)="radioSelected($event)" value="true">{{'yes' | translate}}</mat-radio-button>
            <mat-radio-button (change)="radioSelected($event)" value="false">{{'no' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</div>
