import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { blobToString, cache } from '../util';
import { Environment, User } from '../typings';
import { AuthService, TokenResponse } from './service/auth.service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

interface HttpOptions {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  authenticated?: boolean;
  withCredentials?: boolean;
  redirectLogin?: boolean;
  headers?: HttpHeaders;
  params?: HttpParams;
  body?: any;
  responseType?: 'json' | 'text';
  fullResponse?: boolean;
  reportProgress?: boolean;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  requestAuthToken(environment: Environment): Observable<TokenResponse> {
    return this.httpWrapper<TokenResponse>({
      method: 'GET',
      url: `${environment.cloudwallHost}/webwall/authentication/token`,
      withCredentials: true
    }).pipe(tap((response: TokenResponse) => this.auth.storeTokenAuthentication(response)));
  }

  httpWrapper<T>(opts: HttpOptions): Observable<T> {
    let headers = opts.headers || new HttpHeaders();

    if (opts.authenticated) {
      const authHeaders = this.auth.getHeaders();
      for (const header in authHeaders) {
        if (authHeaders.hasOwnProperty(header)) {
          headers = headers.set(header, authHeaders[header]);
        }
      }
    }

    const responseType = !opts.responseType || opts.responseType === 'json' ? 'text' : opts.responseType;
    return this.http
      .request(opts.method, opts.url, {
        observe: opts.fullResponse ? 'response' : opts.reportProgress ? 'events' : 'body',
        responseType: responseType,
        headers: headers,
        params: opts.params,
        body: opts.body,
        withCredentials: opts.withCredentials,
        reportProgress: opts.reportProgress
      })
      .pipe(
        map(data => {
          if (!opts.reportProgress && (!opts.responseType || opts.responseType === 'json')) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            return data ? JSON.parse(data) : null;
          }
          return data;
        }),
        catchError<T, Promise<never>>(async (err: HttpErrorResponse) => {
          let data: any = err.error;
          try {
            if (data instanceof Blob) {
              data = await blobToString(data);
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            data = JSON.parse(data);
          } catch (e) {}
          throw <any>{
            error: err,
            status: err.status,
            code: data && (data.errorId || data.error),
            data
          };
        }),
        cache()
      );
  }

  getUserInfo(): Observable<User> {
    return this.httpWrapper<User>({
      method: 'GET',
      url: '/api/me',
      authenticated: true
    }).pipe(
      tap((user: User) => {
        this.auth.user$.next(user);
      }),
      cache()
    );
  }
}
