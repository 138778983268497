import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SurveyComponent } from './survey/survey.component';
import { SurveySuccessPageComponent } from './survey/survey-success-page/survey-success-page.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './service/auth.guard';
import { HelloComponent } from './hello/hello.component';

const routes: Routes = [
  {
    path: '',
    component: HelloComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'hello',
    component: HelloComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'survey/:uuid',
    component: SurveyComponent
  },
  {
    path: 'survey-success/:uuid',
    component: SurveySuccessPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
