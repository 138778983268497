<div class="talent-app-checkbox" [ngClass]="invalid &&  !_value ? 'error' : ''">
  <mat-checkbox class="mat-checkbox"
      disableRipple
      [id]="id"
      [(ngModel)]="_value"
      [labelPosition]="labelPosition"
      (change)="checkBox($event)">
    <ng-content></ng-content>
  </mat-checkbox>
</div>
