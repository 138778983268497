import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare let require: any;

const ICONS = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  studios_white: require('!raw-loader!../../assets/images/aquent-studios-logo-light.svg')
};

/**
 * Appends SVGs to the DOM to allow direct usage & CSS properties applied.
 *
 * Notes:
 * - Remove the width/height attributes from the SVG, and use viewBox instead.
 *   (eg. width="20" height="20" can become viewBox="0 0 20 20")
 *   This allows the icon to be sized dynamically:
 *     svg-icon {
 *       display: inline-block;
 *       width: rem-calc(20);
 *       height: rem-calc(20);
 *     }
 *
 * - Run SVGs through SVGO (or online version SVGOMG) and remove any IDs that aren't necessary.
 *   Since the SVGs are appended directly to the DOM, they become global IDs that can overlap.
 *   Some generated SVGs use a <path> as a mask, and can generally be simplified to just use said <path> directly.
 *   See below for more detail.
 *
 * - Set any fill in the SVG to fill="currentColor", allowing CSS to set the color dynamically:
 *     svg-icon {
 *       color: #333;
 *     }
 *
 * Example SVG, with mask being used as content (after being run through SVGO once):

<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="11" height="17">
  <defs>
    <path id="a" d="M0 14.296L6.107 8.1 0 1.904 1.88 0l8 8.1-8 8.1z"/>
  </defs>
  <g fill="none" fill-rule="evenodd" transform="translate(.667 .4)">
    <mask id="b" fill="#fff">
      <use xlink:href="#a"/>
    </mask>
    <use fill="#000" xlink:href="#a"/>
    <g fill="#9E9E9E" mask="url(#b)">
      <path d="M-25.333-24.3h60v61h-60z"/>
    </g>
  </g>
</svg>

 * More optimized SVG:
 * - Uses the mask (path#a) directly (& removing now-unused ID attribute)
 * - Moves the transform="x" from the group (<g>) to the path
 * - Uses viewBox instead of width/height
 * - Sets fill="currentColor" on the path
 * - Removes now-useless xmlns:xlink attribute
 * - Removes redundant fill-rule="even-odd" (default value)

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 17">
  <path d="M0 14.296L6.107 8.1 0 1.904 1.88 0l8 8.1-8 8.1z" transform="translate(.667 .4)" fill="currentColor"/>
</svg>

 * With the transform added directly to the path, we can run it through SVGO/SVGOMG again, and end up with
 * an even more concise version (with transform optimized into to path definition)

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 17">
 <path fill="currentColor" d="M.667 14.696L6.774 8.5.667 2.304 2.547.4l8 8.1-8 8.1z"/>
</svg>

 */
@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent {
  @Input() name: string;

  constructor(private sanitizer: DomSanitizer) {}

  get svgIcon() {
    const iconSvg = ICONS[this.name];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return this.sanitizer.bypassSecurityTrustHtml(iconSvg.default);
  }
}
