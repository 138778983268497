import { Component, Input } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../abstract-value-accessor';

@Component({
  selector: 'app-textarea-box',
  templateUrl: './textarea-box.component.html',
  styleUrls: ['./textarea-box.component.scss'],
  providers: [MakeProvider(TextareaBoxComponent)]
})
export class TextareaBoxComponent extends AbstractValueAccessor {
  @Input() placeholder = '';
  @Input() errorText = '';
  @Input() labelText = '';
  @Input() formControlName = '';
  @Input() name = '';
  @Input() invalid = false;

  propagateText($event: any): void {
    this.onChange($event.target.value);
  }
}
