import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractValueAccessor, MakeProvider} from '../web-components/abstract-value-accessor';

@Component({
  selector: 'app-yes-no-rating-radio-buttons',
  templateUrl: './yes-no-radio-buttons.component.html',
  styleUrls: ['./yes-no-radio-buttons.component.scss'],
  providers: [MakeProvider(YesNoRadioButtonsComponent)]
})
export class YesNoRadioButtonsComponent extends AbstractValueAccessor {
  @Input() label = '';
  @Input() errorMessage = '';
  @Output() valueChanged = new EventEmitter();
  @Input() invalid = false;

  radioSelected($event: any) {
    this.onChange($event.value);
    this.valueChanged.emit($event.value);
  }
}
