<div class="textarea-box-component">
  <div class="grid-x grid-padding-x">
    <div class="cell auto">
      <label class="question-label"
             [htmlFor]="formControlName + 'Input'"
             [innerText]="labelText | translate"
             [ngClass]="invalid && !_value ? 'error-color' : 'normal-color'">
      </label>
      <textarea
        rows="8"
        [id]="formControlName + 'Input'"
        [placeholder]="placeholder | translate"
        [name]="name"
        [(ngModel)]="_value"
        (change)="propagateText($event)"
        [ngClass]="invalid && !_value ? 'input-error' : 'input-normal '"></textarea>
    </div>
  </div>
</div>
