import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { Auth, User } from '../../typings';

const STORAGE_KEY = 'authentication';

export interface TokenResponse {
  token?: string;
  expiresAt?: Date;
}

@Injectable()
export class AuthService {
  constructor(private storage: StorageService) {
    this.auth$.subscribe(auth => this.storeAuth(auth));
  }

  auth$ = new BehaviorSubject<Auth | null>(this.readAuth());
  user$ = new BehaviorSubject<User>(null);

  storeTokenAuthentication(data: TokenResponse): void {
    const expiresAt = new Date();
    expiresAt.setHours(expiresAt.getHours() + 24);
    this.auth$.next({
      token: data.token,
      expiresAt: expiresAt.toISOString() // 24 hours from today
    });
  }

  removeAuthentication(): void {
    this.auth$.next(null);
    this.user$.next(null);
    this.auth$.subscribe(auth => this.storeAuth(auth));
  }

  hasAuthentication(): boolean {
    const today = new Date();
    if (this.auth$.getValue() && this.auth$.getValue().expiresAt < today.toISOString()) {
      return false;
    }
    return this.auth$.getValue() != null;
  }

  getHeaders(): { [key: string]: string } {
    const auth = this.auth$.getValue();
    if (auth) {
      return { authorization: `Token ${auth.token}` };
    }
    return null;
  }

  private readAuth(): Auth | null {
    try {
      return this.storage.get(STORAGE_KEY);
    } catch (e) {
      return null;
    }
  }

  private storeAuth(auth: Auth): void {
    if (auth != null) {
      this.storage.put(STORAGE_KEY, JSON.stringify(auth));
    } else {
      this.storage.remove(STORAGE_KEY);
    }
  }
}
