import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private api: ApiService, private translate: TranslateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.auth.hasAuthentication()) {
      void this.router.navigate(['/login'], { queryParams: { returnUrl: encodeURIComponent(state.url) } });
      return false;
    }
    if (!this.auth.user$.getValue()) {
      return this.api.getUserInfo().pipe(
        map(user => {
          this.auth.user$.next(user);
          // TODO Set this up to work with every language. for some reason defaultlang is not working
          // this.Translate.setDefaultLang('en-US');
          // this.Translate.use(user.locale);
          this.translate.use('en-US');
          return true;
        }),
        catchError(err => {
          console.error('Failed to get user information', err);

          // Failed, redirect to login
          this.auth.removeAuthentication();
          void this.router.navigate(['/error']);
          return of(false);
        })
      );
    } else {
      return true;
    }
  }
}
