<div *ngIf="dataLoaded | async">
  <div class="grid-y" class="main-container survey-container">
    <div class="cell">
      <app-top-bar [isStudios]="isStudiosSurvey"></app-top-bar>
    </div>
    <main>
      <form [formGroup]="formGroup">
        <div class="grid-y form-container grid-padding-y">
          <div class="cell" id="survey-header">
            <h1>{{surveyDetails.headerKey | translate }}</h1>
            <p class="preWrap" *ngIf="!isStudiosSurvey">{{surveyDetails.subHeaderKey | translate}}</p>
            <p class="preWrap" *ngIf="isStudiosSurvey">{{surveyDetails.subHeaderKey | translate: {
              participantName: studiosSurveyDetails.participantName,
              engagementName: studiosSurveyDetails.roboheadEngagementName,
              clientName: studiosSurveyDetails.roboheadClientName,
              date: surveyDate
            } }}</p>
            <hr class="thin">
          </div>
          <ng-container *ngFor="let question of surveyDetails.questions; let i = index">
            <div [ngClass]="'question-themes' + (i !== 0 ? ' question-themes-space-up' : '')" *ngIf="showTheme(question.questionParentTheme.themeId)">
              <span id="question-theme-header" class="question-theme">
                {{question.questionParentTheme.themeDescription === null ? question.questionParentTheme.themeTitle :
                question.questionParentTheme.themeTitle + ': ' + question.questionParentTheme.themeDescription}}
              </span>
            </div>
            <div class="cell small-12 medium-8 question-section" *ngIf="question.questionTypeId === 5">
              <app-five-star-rating-radio-buttons
                [formControlName]="'question' + question.id"
                [label]="question.questionKey | translate"
                [highScoreLabel]="'survey.upper-star-label' | translate"
                [lowScoreLabel]="'survey.lower-star-label' | translate"
                [errorMessage]="'survey.required-message' | translate"
                [invalid]="hasRequiredError(question.id)">
              </app-five-star-rating-radio-buttons>
            </div>
            <div class="cell small-12 medium-8 question-section" *ngIf="question.questionTypeId === 6">
              <app-yes-no-rating-radio-buttons
                [formControlName]="'question' + question.id"
                [id]="question.id"
                [label]="question.questionKey | translate"
                [errorMessage]="'survey.required-message' | translate"
                [invalid]="hasRequiredError(question.id)">
              </app-yes-no-rating-radio-buttons>
            </div>
            <div class="cell small-12 medium-8 question-section" *ngIf="question.questionTypeId === 2">
              <app-textarea-box [id]="'question' + question.id"
                                [name]="question.questionKey"
                                [labelText]="question.questionKey"
                                [formControlName]="'question' + question.id"
                                [invalid]="hasRequiredError(question.id)">
              </app-textarea-box>
            </div>
          </ng-container>
          <div class="cell">
            <button
              id="survey-submit"
              type="submit"
              class="button submit submit-button"
              href="#"
              (click)="submitSurvey()"
            >{{"submit" | translate}}</button>
          </div>
        </div>
      </form>
    </main>
  </div>
</div>





