import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { ApiService } from './api.service';
import { APP_BASE_HREF, DatePipe, PlatformLocation } from '@angular/common';
import { CheckboxComponent } from './web-components/checkbox/checkbox.component';
import { SurveyComponent } from './survey/survey.component';
import { FiveStarRatingRadioButtonsComponent } from './five-star-rating-radio-buttons/five-star-rating-radio-buttons.component';
import { TextareaBoxComponent } from './web-components/textarea-box/textarea-box.component';
import { SurveySuccessPageComponent } from './survey/survey-success-page/survey-success-page.component';
import { YesNoRadioButtonsComponent } from './yes-no-radio-buttons/yes-no-radio-buttons.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './service/auth.service';
import { StorageService } from './service/storage.service';
import { AuthGuard } from './service/auth.guard';
import { HelloComponent } from './hello/hello.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    CheckboxComponent,
    SurveyComponent,
    FiveStarRatingRadioButtonsComponent,
    TextareaBoxComponent,
    SurveySuccessPageComponent,
    YesNoRadioButtonsComponent,
    SvgIconComponent,
    LoginComponent,
    HelloComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule,
    NoopAnimationsModule,
    AgmCoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FormsModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
    ApiService,
    AuthService,
    AuthGuard,
    StorageService,
    DatePipe
  ],
  exports: [CheckboxComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
