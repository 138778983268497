import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { EnvironmentToken } from './util';

if (environment.production) {
  enableProdMode();
}

fetch('/api/environment')
  .then(res => res.json())
  .then(environment => platformBrowserDynamic([{ provide: EnvironmentToken, useValue: environment }]).bootstrapModule(AppModule))
  .catch(err => console.error(err));
